import axios from "axios";

import authService from "./core/services/auth.service";
import { useAuthStore } from "./stores/AuthStore";

const iaxios = axios.create({
  withCredentials: true,
});
let refreshRetryCount = 0;

iaxios.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

iaxios.interceptors.response.use(
  (response) => {
    refreshRetryCount = 0;
    return response;
  },
  async (error) => {
    if (error.code === "ECONNREFUSED") {
      handleLogout();
    } else if (
      (error.request.status === 401 ||
        error.request.status === 403 ||
        error.code === "ERR_NETWORK") &&
      refreshRetryCount < 2
    ) {
      refreshRetryCount++;
      await handleRefreshToken();

      // Retry the original request
      const originalRequest = error.config;
      originalRequest.headers = {
        ...originalRequest.headers,
        ...authService.authHeaderCognito(),
      };
      return iaxios(originalRequest);
    } else {
      return Promise.reject(error);
    }
  },
);

function handleLogout() {
  const authStore = useAuthStore();
  authStore.logoutAfterNewtorkError();
}

async function handleRefreshToken() {
  const authStore = useAuthStore();
  return authStore.refreshToken();
}

export default iaxios;
